import React, { createContext, useEffect, useState } from 'react'
import { Mocion } from 'src/interfaces/Mocion'
import useFetch from 'src/hooks/useFetch';
import { IP } from 'src/router/routes'

const defaultValue: { mociones: Mocion[] | null, setMociones: Function } = {
  mociones: [],
  setMociones: () => {}
}

const MocioneroContext = createContext(defaultValue);

export default MocioneroContext;

export interface IMocioneroContextProviderProps {
  children: React.ReactNode
}

export const MocioneroContextProvider: React.FunctionComponent<IMocioneroContextProviderProps> = (props) => {
  const fetchMociones = useFetch<Mocion[]>(`${IP}/mociones`)
  const [mociones, setMociones] = useState<Mocion[] | null>(null)

  useEffect(() => {
    if (fetchMociones.state === 'success') {
      setMociones(fetchMociones.data)
    }
  }, [fetchMociones])


  const mocioneroContextValue = {
    mociones: mociones,
    setMociones: (mociones: Mocion[]) => setMociones(mociones)
  }

  return (
    <MocioneroContext.Provider value={mocioneroContextValue}>
      {props.children}
    </MocioneroContext.Provider>
  )
}

