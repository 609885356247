import { useEffect } from 'react';
import { analytics } from 'src/utils/analytics';
import MocionesView from './View';

export interface IMocionesProps { }

const Mociones: React.FunctionComponent<IMocionesProps> = (props) => {

  useEffect(() => {
    analytics('Page - Mociones')
  }, [])
  return (
    <>
      <h1>Mocionero</h1>
      <MocionesView/>
    </>
  )
}

export default Mociones;