import React, { ReactElement } from 'react'

import Mociones from 'src/views/mociones';
import Contribute from 'src/views/contribute';
import Mocionero from 'src/views/mocionero';
import RandomMocion from 'src/views/randomMocion';

var IP_TEMP
if (process.env.NODE_ENV === "development") {
  IP_TEMP = 'http://localhost:5001/api'
} else {
  IP_TEMP = "https://mocionero-apirest.onrender.com/api"
}

export const IP = IP_TEMP
interface Route {
  title: string,
  path: string,
  element: ReactElement
}

export const contribute = '/contribute'
export const random = '/random'
export const mocionero = '/mocionero'

export const routes: Route[] = [
  {
    title: 'Mociones',
    path: '/',
    element: <Mociones />
  },
  {
    title: 'Aportar mociones',
    path: contribute,
    element: <Contribute />
  },
  {
    title: 'Moción aleatoria',
    path: random,
    element: <RandomMocion />
  },
  {
    title: 'Mocionero',
    path: mocionero,
    element: <Mocionero />
  },
]