import { Mocion } from 'src/interfaces/Mocion'

const removeAccents = (str: string) => {
  return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const applySearchFilter = (mociones: Mocion[], searchFilter: string) => {
  if (searchFilter) {
    const queryWords = searchFilter.split(' ').filter(queryWord => queryWord.length > 0).map(queryWord => removeAccents(queryWord))
    return mociones.filter((mocion: Mocion) => {
      let matches = false;
      const mocionAux = removeAccents(mocion.mocion).toLowerCase()
      matches = queryWords.every(queryWord => mocionAux.split(' ').includes(queryWord) || mocionAux.includes(queryWord))
      const keywords = mocion.keywords

      keywords.forEach(keyword => {
        if (removeAccents(keyword.toLowerCase()).includes(searchFilter.toLowerCase()) || removeAccents(keyword.toLowerCase()).includes(searchFilter.toLowerCase())) {
          matches = true;
        }
      })

      return matches
    })
  } else return mociones
};
export const applyTopicFilter = (mociones: Mocion[], topicFilters: string[]) => {
  if (topicFilters.length) {
    return mociones.filter((mocion: Mocion) => {
      let matches = true;

      let containsQuery = false;

      const mocionTopic = removeAccents(mocion.topic)
      topicFilters.forEach(topicFilter => {
        if (removeAccents(topicFilter.toLowerCase()) === mocionTopic.toLowerCase()) {
          containsQuery = true;
        }
      })
      if (!containsQuery) {
        matches = false;
      }

      return matches;
    });
  } else return mociones
};
export const applyTournamentFilter = (mociones: Mocion[], tournamentFilter: string) => {
  if (tournamentFilter) {
    return mociones.filter((mocion: Mocion) => {
      let matches = true;
      if (mocion.tournament.toLowerCase() !== tournamentFilter.toLowerCase()) {
        matches = false;
      }
      return matches;
    });
  } else return mociones
};

export const applyPagination = (mociones: Mocion[], limit: number) => {
  return mociones?.slice(0, limit);
};
