import React from 'react';
import { TextField, Theme, useTheme, useMediaQuery, Snackbar, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from 'src/components/MocioneroComponents/Button';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { MocionAndRound } from './index';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  tournamentContributorDiv: {
    display: 'flex',
    marginBottom: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tournamentContributorText: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  mocionAndRoundOuterDiv: {
    display: 'flex',
    marginTop: 16,
    marginBottom: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mocionAndRoundInnerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mocionTextfield: {
    width: '69%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  roundTextfield: {
    width: '29%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submitButtonDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonAddMocionRoundDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },
  buttonAddMocionRound: {
    display: 'flex',
    padding: '4px 32px',
    alignItems: 'center'
  }
}))

export interface IContributeViewProps {
  handleSubmit: Function
  mocionesAndRounds: MocionAndRound[]
  handleChangeMocionRound: Function
  handleAddMocionRound: Function
  handleDeleteMocionRound: Function
  tournament: string
  setTournament: Function
  contributor: string
  setContributor: Function
  handleCloseSnackbar: Function
  showSnackbar: boolean
  handleCloseSnackbarWarning: Function
  showSnackbarWarning: boolean
  clearAllFields: Function
  contactInfo: string
  setContactInfo: Function
  tournamentInfo: string
  setTournamentInfo: Function
}

const ContributeView: React.FunctionComponent<IContributeViewProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.root}>
      <h1> ¡Aporta al Mocionero! </h1>
      <p style={{ margin: '24px 0px' }}>
        Introduce el torneo o fuente y a continuación podrás mandar una o varias mociones.
        Por favor, antes de enviar las mociones de un torneo, revisa que este todavía no aparezca en el sitio. Además, dentro de lo posible, envía todas las mociones usadas en el torneo, y detalla en qué ronda se usó cada una.
      </p>
      <Button
        onClick={() => props.clearAllFields()}
        variant='secondary'
        style={{ marginBottom: 24, padding: '4px 16px' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon style={{ marginRight: 4 }} /> Limpiar todos los campos
        </div>
      </Button>
      <form onSubmit={event => props.handleSubmit(event)}>

        <div className={classes.tournamentContributorDiv}>
          <TextField
            className={classes.tournamentContributorText}
            style={{ marginBottom: downSm ? 16 : 0 }}
            onChange={event => props.setTournament(event.target.value)}
            label="Torneo o fuente"
            multiline
            variant="outlined"
            value={props.tournament}
            required
          />
          <TextField
            className={classes.tournamentContributorText}
            style={{ marginBottom: downSm ? 16 : 0 }}
            onChange={event => props.setTournamentInfo(event.target.value)}
            label="Información del torneo"
            helperText="Enlaces a información del torneo o la fuente (cuentas de redes sociales, tabulación completa, etc.)"
            multiline
            variant="outlined"
            value={props.tournamentInfo}
          />
        </div>
        <div className={classes.tournamentContributorDiv}>
          <TextField
            className={classes.tournamentContributorText}
            style={{ marginBottom: 16 }}
            multiline
            onChange={event => props.setContributor(event.target.value)}
            label="Aporte de"
            variant="outlined"
            helperText="Este nombre podría aparecer en el Mocionero junto a las mociones aportadas; en caso de no querer aparecer, puedes aclararlo junto a tu nombre"
            value={props.contributor}
            required
          />
          <TextField
            className={classes.tournamentContributorText}
            style={{ marginBottom: 16 }}
            multiline
            onChange={event => props.setContactInfo(event.target.value)}
            label="Información de contacto"
            variant="outlined"
            helperText="Email, usuario en las redes sociales, etc.; por si necesitamos contactarte desde el equipo del Mocionero"
            value={props.contactInfo}
          />
        </div>
        {
          props.mocionesAndRounds.map((mocionAndRound: MocionAndRound, index: number) => (
            <div className={classes.mocionAndRoundOuterDiv} key={index}>
              <div className={classes.mocionAndRoundInnerDiv}>
                <TextField
                  className={classes.mocionTextfield}
                  style={{ marginBottom: downSm ? 8 : 0 }}
                  onChange={event => props.handleChangeMocionRound(event.target.value, null, index)}
                  label="Moción"
                  variant="outlined"
                  value={mocionAndRound.mocion}
                  required
                  multiline
                />
                <TextField
                  className={classes.roundTextfield}
                  onChange={event => props.handleChangeMocionRound(null, event.target.value, index)}
                  label="Ronda o instancia"
                  variant="outlined"
                  value={mocionAndRound.round}
                />
              </div>
              <IconButton
                disabled={index === 0 && props.mocionesAndRounds.length === 1}
                onClick={() => props.handleDeleteMocionRound(index)}
                style={{ marginLeft: 4 }}
              >
                <ClearIcon />
              </IconButton>
            </div>
          ))
        }

        <div className={classes.buttonAddMocionRoundDiv}>
          <Button
            onClick={(event: React.FormEvent<HTMLFormElement>) => props.handleAddMocionRound(event)}
            variant='secondary'
            className={classes.buttonAddMocionRound}
            disabled={!props.mocionesAndRounds[props.mocionesAndRounds.length - 1].mocion}
          >
            <AddIcon style={{ marginRight: 8 }} />
            Añadir moción del mismo torneo/fuente
          </Button>
        </div>




        <div className={classes.submitButtonDiv}>
          <Button
            type='submit'
            onClick={(event: React.FormEvent<HTMLFormElement>) => props.handleSubmit(event)}
            style={{ padding: '4px 80px' }}
          >
            Enviar {props.mocionesAndRounds.length === 1 ? 'Moción' : 'Mociones'}
          </Button>
        </div>
      </form>
      <Snackbar open={props.showSnackbar} autoHideDuration={6000} onClose={() => props.handleCloseSnackbar()}>
        <Alert onClose={() => props.handleCloseSnackbar()} severity="success" sx={{ width: '100%' }}>
          ¡Moción enviada correctamente, muchas gracias por contribuir!
        </Alert>
      </Snackbar>
      <Snackbar open={props.showSnackbarWarning} autoHideDuration={6000} onClose={() => props.handleCloseSnackbarWarning()}>
        <Alert onClose={() => props.handleCloseSnackbarWarning()} severity="warning" sx={{ width: '100%' }}>
          Introduce toda la información (moción, torneo y aporte)
        </Alert>
      </Snackbar>
    </div>
  )
}

export default ContributeView;