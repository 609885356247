import { IconButton, Theme, Tooltip } from '@mui/material';
import { makeStyles, useTheme, } from '@mui/styles';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useMocioneroContext from 'src/context/useMocioneroContext';
import { Mocion } from 'src/interfaces/Mocion';
import { useState } from 'react';
import { analytics } from 'src/utils/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },

}))

export interface IMocionesSortProps {
  // topicFilters: string[]
  reRender: Function
}

const MocionesSort: React.FunctionComponent<IMocionesSortProps> = (props) => {

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const [sort, setSort] = useState<'date' | 'shuffle'>('date')
  const { mociones, setMociones } = useMocioneroContext()

  function shuffleMociones() {
    setSort('shuffle')
    analytics('Mociones - Sort Shuffle')
    let mocionesAux = mociones
    if (mocionesAux?.length) {
      let currentIndex = mocionesAux.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [mocionesAux[currentIndex], mocionesAux[randomIndex]] = [
          mocionesAux[randomIndex], mocionesAux[currentIndex]];
      }

      setMociones(mocionesAux)
      props.reRender()
    }
  }

  function sortByDate() {
    analytics('Mociones - Sort Date')
    setSort('date')
    let mocionesAux = mociones
    mocionesAux?.sort(function (a: Mocion, b: Mocion) {
      return new Date(b.update_date).getTime() - new Date(a.update_date).getTime();
    });
    setMociones(mocionesAux)
    props.reRender()
  }

  return (
    <div className={classes.root}>
      <Tooltip title="Ordenar por fecha">
        <IconButton 
          onClick={sortByDate} 
          size='large'
          >
          <CalendarMonthIcon style={{ color: sort === 'date' ? theme.palette.primary.main : theme.palette.secondary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Barajar mociones">
        <IconButton
          onClick={shuffleMociones}
          size='large'
          style={{marginLeft: 4}}
        >
          <ShuffleIcon  
            style={{ 
              color: sort === 'shuffle' ? theme.palette.primary.main : theme.palette.secondary.main }} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default MocionesSort;
