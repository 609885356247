
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    cursor: 'pointer',
    border: 0,
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.75',
    minWidth: 64,
    padding: '6px 16px',
    borderRadius: 28,
    WebkitTransition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'default'
    },
  },
  primary: {
    color: 'black',
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      boxShadow: '0 1px 3px 0 rgba(60,64,67,0.302),0 4px 8px 3px rgba(60,64,67,0.149)',
    },
    '&:disabled': {
      boxShadow: 'none'
    },
  },
  secondary: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: `2px solid ${theme.palette.primary.main}`,
  },
  disabled: {
  },
}));

export interface IButtonProps {
  onClick: Function
  children: React.ReactNode
  style?: object | undefined
  className?: string | undefined,
  disabled?: boolean | undefined,
  variant?: 'primary' | 'secondary' 
  type?:  undefined | "submit" 
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
  const classes = useStyles()

  return (
    <button
      className={`
        ${classes.root} 
        ${props.variant === 'secondary' ? classes.secondary : classes.primary}
        ${props.className}
      `}
      style={props.style}
      onClick={(event) => props.onClick(event)}
      disabled={props.disabled}
      type={props.type}
    >
      {props.children}
    </button>
  )
}

export default Button;