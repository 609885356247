
async function postData(url = '', data = {}, method = 'POST') {
  const response = await fetch(url, {
    method: method, 
    mode: 'cors', 
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', 
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer', 
    body: JSON.stringify(data) 
  });
  let object = await response.json() 
  object = Object.assign(object, { 'status': response.status })

  return object;
}

export default postData;