import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Theme, useMediaQuery, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Mocion } from 'src/interfaces/Mocion'
import Autocomplete from '@mui/material/Autocomplete';
import { analytics } from 'src/utils/analytics';



const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '32%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}))

export interface IMocionesFilterTournamentProps {
  tournamentFilter: string
  setTournamentFilter: Function
  mociones: Mocion[]
}

const MocionesFilterTournament: React.FunctionComponent<IMocionesFilterTournamentProps> = (props) => {

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const [tournaments, setTournaments] = useState<string[]>([])

  useEffect(() => {
    if (props.mociones) {
      let tournamentAux: string[] = []
      props.mociones.forEach((mocion: Mocion) => {
        if (!tournamentAux.includes(mocion.tournament) && mocion.tournament !== '-') tournamentAux.push(mocion.tournament)
      })
      tournamentAux.sort((a, b) => a.localeCompare(b))
      setTournaments(tournamentAux)
    }
  }, [props.mociones])


  const handleChange = (value : string) => {
    analytics('Mociones - Filter Tournament', {tournament: value})
    props.setTournamentFilter(value);
  };

  return (
    <>
      <FormControl className={classes.root} style={{ marginTop: downMd ? 8 : 'auto' }}>
        <Autocomplete
          disablePortal
          onChange={(event: React.SyntheticEvent, value: any) => handleChange(value)}
          id="combo-box-demo"
          options={tournaments}
          renderInput={(params) => <TextField {...params} label="Torneo o fuente" />}
        />
          </FormControl>
    </>
  )
}

export default MocionesFilterTournament;
