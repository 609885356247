import { useState, useReducer, useEffect } from 'react'
import React, { } from 'react';
import ContributeView from './View';
import postData from 'src/utils/postData';
import { analytics } from 'src/utils/analytics';
import { IP } from 'src/router/routes'

export interface MocionAndRound {
  mocion: string,
  round?: string
}

const EMPTY_MOCION_ROUND = { mocion: '', round: '' }

export interface IContributeProps { }

const Contribute: React.FunctionComponent<IContributeProps> = (props) => {

  const [mocionesAndRounds, setMocionesAndRounds] = useState<MocionAndRound[]>([{ ...EMPTY_MOCION_ROUND }])
  const [tournament, setTournament] = useState<string>('')
  const [contributor, setContributor] = useState<string>('')
  const [contactInfo, setContactInfo] = useState<string>('')
  const [tournamentInfo, setTournamentInfo] = useState<string>('')
  const [, reRender] = useReducer((i) => i + 1, 0)

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [showSnackbarWarning, setShowSnackbarWarning] = useState<boolean>(false)

  useEffect(() => {
    analytics('Page - Contribute')
  }, [])

  function handleChangeMocionRound(mocion: string | null = null, round: string | null = null, index: number) {
    let mocionesAndRoundsAux = mocionesAndRounds
    if (mocion !== null) mocionesAndRoundsAux[index].mocion = mocion
    if (round !== null) mocionesAndRoundsAux[index].round = round
    setMocionesAndRounds(mocionesAndRoundsAux)
    reRender()
  }

  function handleAddMocionRound(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const mocionesAndRoundsLength = mocionesAndRounds.length
    if (mocionesAndRounds[mocionesAndRoundsLength - 1].mocion) {
      let mocionesAndRoundsAux = mocionesAndRounds
      mocionesAndRoundsAux.push({ ...EMPTY_MOCION_ROUND })
      setMocionesAndRounds(mocionesAndRoundsAux)
      reRender()
    }
  }

  function handleDeleteMocionRound(index: number) {
    let mocionesAndRoundsAux = mocionesAndRounds
    mocionesAndRoundsAux = mocionesAndRoundsAux.filter((_, indexAux: number) => index !== indexAux)
    setMocionesAndRounds(mocionesAndRoundsAux)
    reRender()
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!mocionesAndRounds[0].mocion.length || !tournament.length || !contributor.length) {
      setShowSnackbarWarning(true)
    } else {
      let mocionesAndRoundsAux = mocionesAndRounds
      mocionesAndRoundsAux = mocionesAndRoundsAux.filter((mocionesAndRoundsAux: MocionAndRound) => mocionesAndRoundsAux.mocion.length)
      const data: any = {
        mocionesAndRounds: mocionesAndRoundsAux,
        tournament: tournament,
        tournamentInfo: tournamentInfo,
        contributor: contributor,
        contactInfo: contactInfo,
      }
      await postData(`${IP}/mocion`, data)
      setShowSnackbar(true)
    }
  }

  function handleCloseSnackbar(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };
  function handleCloseSnackbarWarning(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbarWarning(false);
  };

  function clearAllFields() {
    setMocionesAndRounds([{ ...EMPTY_MOCION_ROUND }])
    setTournament('')
    setContributor('')
    setContactInfo('')
    setTournamentInfo('')
    reRender()
  }

  return (
    <ContributeView
      handleSubmit={handleSubmit}
      handleCloseSnackbar={handleCloseSnackbar}
      mocionesAndRounds={mocionesAndRounds}
      handleChangeMocionRound={handleChangeMocionRound}
      handleAddMocionRound={handleAddMocionRound}
      handleDeleteMocionRound={handleDeleteMocionRound}
      tournament={tournament}
      setTournament={setTournament}
      contributor={contributor}
      setContributor={setContributor}
      contactInfo={contactInfo}
      setContactInfo={setContactInfo}
      tournamentInfo={tournamentInfo}
      setTournamentInfo={setTournamentInfo}
      showSnackbar={showSnackbar}
      showSnackbarWarning={showSnackbarWarning}
      handleCloseSnackbarWarning={handleCloseSnackbarWarning}
      clearAllFields={clearAllFields}
    />
  )
}

export default Contribute;