import { Routes, Route } from 'react-router-dom';
import { routes } from 'src/router/routes'

export function renderRoutes() {
  return (
    <Routes>
      {
        routes.map(route => {
          return (
            <Route
              key={route.title}
              path={route.path}
              element={route.element}
            />
          )
        })
      }
    </Routes>
  )
}