import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  Theme,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Button from 'src/components/MocioneroComponents/NavbarButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'src/router/routes'
import MenuIcon from '@mui/icons-material/Menu';
import MobileDrawer from './MobileDrawer';
import Socials from 'src/components/Socials/Socials';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'white',
    borderRadius: '0px 0px 8px 8px',
    boxShadow: 'rgb(220, 220, 220) 0px 2px 10px',
    position: 'fixed',
    width: '100%',
    height: 64,
    zIndex: 2,
  },
  routesSocialsDiv: {
    display: 'flex',
    height: '100%',
    marginLeft: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    marginLeft: 8,
  },
  routesDiv: {
    display: 'flex',
  },
  socialsDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginRight: '104px',
    [theme.breakpoints.down(950)]: {
      display: 'none'
    },
  },
  logoDiv: {
    right: '16px',
    position: 'fixed',
    top: '8px',
    cursor: 'pointer'
  },
  logo: {
    width: 48,
    height: 48
  },
}));


export interface INavbarProps { }

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <div className={classes.root}>
      <div className={classes.routesSocialsDiv}>
        {
          downSm
            ? <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            : <div className={classes.routesDiv}>
              {
                routes.map(route => {
                  return (
                    <Button
                      key={route.title}
                      isSelected={location.pathname === route.path}
                      onClick={() => navigate(route.path)}
                      className={classes.button}
                    >
                      {route.title}
                    </Button>
                  )
                })
              }
            </div>
        }
        <div className={classes.socialsDiv}>
          <Socials />
        </div>
      </div>
      <div className={classes.logoDiv} onClick={() => navigate('/')}>
        <img src={'mocionero-logo.png'} alt='logo' className={classes.logo} />
      </div>
      {
        downSm
        &&
        <MobileDrawer
          mobileDrawerOpen={mobileDrawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      }
    </div>
  )
}

export default Navbar;
