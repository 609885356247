import React, { } from 'react';
import { makeStyles } from '@mui/styles';
import Navbar from 'src/layout/Navbar'
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    background: {
        backgroundImage: 'url(/assets/images/background.png)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: -1,
        opacity: 0.3
    },
    navbar: {
    },
    content: {
        padding: 32,
        paddingTop: 80,
        top: 100,
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 8,
            paddingTop: 72,
        },
    },
    contentRoot: {}
}));


export interface ILayoutProps { children: React.ReactNode }

const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.background}/>
            <div className={classes.navbar}>
                <Navbar />
            </div>
            <div className={classes.content}>
                <div className={classes.contentRoot}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Layout;
