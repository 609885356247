import {
  Box,
  Drawer,
  Divider,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Socials from 'src/components/Socials/Socials';
import { routes } from 'src/router/routes'

const drawerWidth = 240;

export interface IMobileDrawerProps {
  mobileDrawerOpen: boolean
  handleDrawerToggle: Function
}

const MobileDrawer: React.FunctionComponent<IMobileDrawerProps> = (props) => {
  const navigate = useNavigate()

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {routes.map(route => (
          <ListItem key={route.title} disablePadding>
            <ListItemButton
              onClick={() => { navigate(route.path); props.handleDrawerToggle() }}
            >
              <ListItemText primary={route.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div style={{margin: '16px 0px', display: 'flex', justifyContent: 'center'}}>
        <Socials />
      </div>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileDrawerOpen}
        onClose={() => props.handleDrawerToggle()}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default MobileDrawer;
