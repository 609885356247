import { Theme, InputAdornment, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '8px 0px',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}))

export interface IMocionesFilterSearchProps {
  searchFilter: string
  setSearchFilter: Function
}

const MocionesFilterSearch: React.FunctionComponent<IMocionesFilterSearchProps> = (props) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.root}
      onChange={event => props.setSearchFilter(event.target.value)}
      label="Buscar por moción o palabras clave"
      variant="outlined"
      value={props.searchFilter}
      InputProps={{
        endAdornment: (
          props.searchFilter && (
            <InputAdornment position='end'>
              <IconButton onClick={() => props.setSearchFilter('')}>
                <ClearIcon style={{ padding: 0, cursor: 'pointer' }} id='clearQuery' />
              </IconButton>
            </InputAdornment>)
        ),
      }}
    />
  )
}

export default MocionesFilterSearch;