import RandomMocionView from './View';
import LinearProgress from '@mui/material/LinearProgress';
import useMocioneroContext from 'src/context/useMocioneroContext';
import { analytics } from 'src/utils/analytics';
import { useEffect } from 'react';


export interface IRandomMocionProps { }

const RandomMocion: React.FunctionComponent<IRandomMocionProps> = (props) => {

  const { mociones } = useMocioneroContext()

  useEffect(() => {
    analytics('Page - Random Mocion')
  }, [])

  return (
    <>
      <h1>Generador mociones aleatorias</h1>
      {
        mociones
          ? <RandomMocionView mociones={mociones} />
          : <LinearProgress />
      }
    </>
  )
}

export default RandomMocion;