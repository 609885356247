import Socials from 'src/components/Socials/Socials';
import { TextField, Theme, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from 'src/components/MocioneroComponents/Button';
import { useNavigate } from 'react-router-dom';
import { contribute } from 'src/router/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  commentDiv: {
    width: '100%'
  },
  commentTextfield: {
    width: '100%'
  },
  redirectText: {
    cursor: 'pointer',
    display: 'inline',
    color: theme.palette.primary.main
  }
}));

export interface IMocioneroProps {
  comment: string
  setComment: Function
  sendComment: Function
  showSnackbarWarning: boolean | undefined
  setShowSnackbarWarning: Function
  showSnackbarSuccess: boolean | undefined
  setShowSnackbarSuccess: Function
}

const Mocionero: React.FunctionComponent<IMocioneroProps> = (props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  function goToContribute() {
    navigate(contribute)
  }

  return (
    <div className={classes.root}>
      <h1>
        Mocionero
      </h1>
      <h2>
        Banco de mociones de debate
      </h2>
      <p>
        El Mocionero es un sitio en el que se registran mociones de debate en castellano de manera libre y gratuita y se ponen a disposición para quienes quieran utilizarlas.
      </p>
      <p>
        Las mociones provienen de torneos de debate, sesiones de formación, manuales y otras fuentes. Son editadas para mantener cierta homogeneidad. Además, se hacen ediciones ortográficas y se traducen mociones originalmente escritas en otros idiomas.
      </p>
      <p>
        El sitio permite buscar por palabra clave, tema, fuente/torneo o fecha de publicación.
      </p>
      <p
        style={{ display: 'inline' }}
      >
        Si quieres aportar mociones, utiliza el formulario disponible
      </p>
      <p onClick={goToContribute} className={classes.redirectText}> aqui</p>.

      <p>
        ¡Te invitamos a participar del Mocionero y difundirlo!
      </p>
      <p>
        Juan Mamberti (coordinador)
        <br />
        Paula Fiorini (editora)
        <br />
        Javier Crespo (programador)
      </p>
      <h3>
        <b>¡Déjanos tu comentario!</b> Cuéntanos tu experiencia con el Mocionero. Si quieres, puedes indicarnos cómo podríamos ponernos en contacto contigo.
      </h3>
      <div
        className={classes.commentDiv}
      >
        <TextField
          className={classes.commentTextfield}
          onChange={event => props.setComment(event.target.value)}
          label="Comentario acerca del Mocionero "
          variant="outlined"
          value={props.comment}
          required
          rows={2}
          multiline
        />
      </div>
      <Button
        onClick={() => props.sendComment()}
        style={{ margin: '16px 0px' }}
      >
        Enviar comentario
      </Button>
      <div>
        <Socials />
      </div>
      <Snackbar open={props.showSnackbarWarning} autoHideDuration={6000} onClose={() => props.setShowSnackbarWarning(false)}>
        <Alert onClose={() => props.setShowSnackbarWarning(false)} severity={'warning'} sx={{ width: '100%' }}>
          Introduce un comentario
        </Alert>
      </Snackbar>
      <Snackbar open={props.showSnackbarSuccess} autoHideDuration={6000} onClose={() => props.setShowSnackbarSuccess(false)}>
        <Alert onClose={() => props.setShowSnackbarSuccess(false)} severity={'success'} sx={{ width: '100%' }}>
          ¡Comentario mandado correctamente
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Mocionero;