import { useState, useEffect, useReducer } from 'react';
import { Mocion } from 'src/interfaces/Mocion'
import MocionesFilterTopic from './filters/MocionesFilterTopic';
import MocionesFilterSearch from './filters/MocionesFilterSearch';
import { Theme, Divider, LinearProgress, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { applyTournamentFilter, applyTopicFilter, applySearchFilter, applyPagination } from './filters/applyFilters'
import MocionRow from 'src/components/Mocion/MocionRow'
import InfiniteScroll from "react-infinite-scroll-component";
import MocionesFilterTournament from './filters/MocionesFilterTournament';
import MocionesSort from './filters/MocionesSort';

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    display: 'flex',
    margin: '8px 0px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  mociones: {
    overflow: 'hidden',
    boxShadow: "0px 8px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,
    marginTop: 16,
  },
  results: {
    margin: '16px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))
export interface IMocionesTableProps {
  mociones: Mocion[]
}

const MocionesTable: React.FunctionComponent<IMocionesTableProps> = (props) => {

  const classes = useStyles()
  const theme = useTheme()
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [tournamentFilter, setTournamentFilter] = useState<string>('')
  const [topicFilters, setTopicFilters] = useState<string[]>([])
  const [limit, setLimit] = useState<number>(20)
  const [hasMore, setHasMore] = useState(true)
  const [, reRender] = useReducer((i) => i + 1, 0)

  let mocionesFiltered = applySearchFilter(props.mociones, searchFilter)
  mocionesFiltered = applyTopicFilter(mocionesFiltered, topicFilters)
  mocionesFiltered = applyTournamentFilter(mocionesFiltered,tournamentFilter)
  const mocionesLimited = applyPagination(mocionesFiltered, limit)

  useEffect(() => {
    if (!mocionesFiltered.length && hasMore) {
      if (limit >= mocionesFiltered.length) {
        setHasMore(false)
      }
    }
  }, [mocionesFiltered, limit, hasMore])

  const getMoreMociones = () => {
    if (limit >= mocionesFiltered.length) {
      setHasMore(false)
      return;
    }
    setTimeout(() => {
      setLimit(limit => limit + 10);
    }, 500);
  };

  return (
    <>
      <div className={classes.filters}>
        <MocionesFilterSearch
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
        <MocionesFilterTopic
          topicFilters={topicFilters}
          setTopicFilters={setTopicFilters}
        />
        <MocionesFilterTournament
          tournamentFilter={tournamentFilter}
          setTournamentFilter={setTournamentFilter}
          mociones={props.mociones}
        />
      </div>
      <div className={classes.results}>
        <div>
          <Typography display='inline'>
            Resultados:
          </Typography>
          <Typography display='inline' style={{ marginLeft: 8, color: theme.palette.primary.main }}>
            {mocionesFiltered.length}
          </Typography>
        </div>
        <MocionesSort reRender={reRender} />
      </div>
      <div className={classes.mociones}>
        <InfiniteScroll
          dataLength={mocionesLimited?.length ?? 0}
          next={getMoreMociones}
          hasMore={hasMore}
          loader={<LinearProgress />}
          endMessage={<div style={{ padding: 16 }}> No hay más mociones </div>}
        >
          {
            mocionesLimited.map((mocion, index) => (
              <div key={mocion.id} style={{ backgroundColor: index % 2 === 0 ? '#e1ebf2' : 'white' }}>
                <MocionRow key={mocion.id} mocion={mocion} />
                <Divider />
              </div>
            ))
          }
        </InfiniteScroll>
      </div>
    </>
  )
}

export default MocionesTable;