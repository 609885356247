import React, {  } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'src/layout/index';
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from 'src/theme/theme'
import { renderRoutes } from 'src/router/renderRoutes'
import { MocioneroContextProvider } from './context/MocioneroContext';
import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles';


export interface IAplicationProps { }

const App: React.FunctionComponent<IAplicationProps> = (props) => {
  const theme: Theme = createTheme(getTheme('light'))
  
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <BrowserRouter>
        <MocioneroContextProvider>
          <Layout>
            {renderRoutes()}
          </Layout>
        </MocioneroContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
