
import React, { useState, useEffect } from 'react';
import postData from 'src/utils/postData';
import { analytics } from 'src/utils/analytics';
import View from './View'
import { IP } from 'src/router/routes'

export interface IMocioneroProps { }

const Mocionero: React.FunctionComponent<IMocioneroProps> = (props) => {

  const [comment, setComment] = useState<string>('')
  const [showSnackbarWarning, setShowSnackbarWarning] = useState<boolean | undefined>(false)
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState<boolean | undefined>(false)

  useEffect(() => {
    analytics('Page - El Mocionero')
  }, [])

  async function sendComment() {
    if (!comment.length) {
      setShowSnackbarWarning(true)
    } else {
      const data: object = {
        comment: comment
      }
      await postData(`${IP}/setcomment`, data)
      setShowSnackbarSuccess(true)
    }
  }

  return (
    <View
      sendComment={sendComment}
      comment={comment}
      setComment={setComment}
      showSnackbarWarning={showSnackbarWarning}
      setShowSnackbarWarning={setShowSnackbarWarning}
      showSnackbarSuccess={showSnackbarSuccess}
      setShowSnackbarSuccess={setShowSnackbarSuccess}
    />
  )
}

export default Mocionero;