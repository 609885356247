import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  Theme,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  logoButton: {
    width: 48,
    height: 48
  },
  social: {
    color: theme.palette.primary.main
  },
}));


export interface ISocialsProps { }

const Socials: React.FunctionComponent<ISocialsProps> = (props) => {
  const classes = useStyles()

  return (
    <>
      <IconButton
        className={classes.logoButton}
        onClick={() => window.open('https://twitter.com/mocionerodebate')}
      >
        <TwitterIcon className={classes.social} />
      </IconButton>
      <IconButton
        className={classes.logoButton}
        onClick={() => window.open('https://www.instagram.com/mocionero.debate/')}
      >
        <InstagramIcon className={classes.social} />
      </IconButton>
      <IconButton
        className={classes.logoButton}
        onClick={() => window.open('https://www.facebook.com/mocionero')}
      >
        <FacebookIcon className={classes.social} />
      </IconButton>
      <IconButton
        className={classes.logoButton}
        onClick={() => window.location.href = 'mailto:jmamberti@gmail.com'}
      >
        <EmailIcon className={classes.social} />
      </IconButton>

    </>
  )
}

export default Socials;
