import { deepOrange, grey } from '@mui/material/colors';
import { PaletteMode } from '@mui/material';
import { typography } from './typography';

// https://coolors.co/e7ecef-274c77-6096ba-a3cef1-8b8c89

export const getTheme = (mode: PaletteMode) => ({
  typography: typography,
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        primary: {
          light: '#a3cef1',
          main: '#6096BA',
          dark: '#274C77',
        },
        secondary: {
          light: '#E7ECEF',
          main: '#8B8C89',
        },
        // divider: amber[200],
        text: {
          primary: grey[900],
          secondary: grey[800],
        },
      }
      : {
        // palette values for dark mode
        primary: deepOrange,
        divider: deepOrange[700],
        background: {
          default: deepOrange[900],
          paper: deepOrange[900],
        },
        text: {
          primary: '#fff',
          secondary: grey[500],
        },
      }),
  },
});

