import { useState } from 'react';
import { Mocion } from 'src/interfaces/Mocion'
import { Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
const MAX_LETTERS = 300

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 16,
  },
  mocion: {
  },
  titleMocion: {
    color: theme.palette.primary.main,
    display: 'inline',
    textTransform: 'none',
  },
  mocionSeeMore: {
    display: 'inline',
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
  titleOther: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'inline'
    },
  },
  other: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  otherRow: {
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}))

export interface IMocionRowProps {
  mocion: Mocion
}

const MocionRow: React.FunctionComponent<IMocionRowProps> = (props) => {
  const classes = useStyles()
  const [showFullMocion, setShowFullMocion] = useState(false)
  const [showOriginalMocion, setShowOriginalMocion] = useState(false)

  function renderMocion(mocion: string, showAll: boolean = showFullMocion) {
    if (mocion.length < MAX_LETTERS) {
      return <Typography style={{ display: 'inline', color: 'black' }}> {mocion} </Typography>
    } else {
      return (
        <>
          <Typography style={{ display: 'inline', color: 'black' }}> {showAll ? mocion : `${mocion.substring(0, MAX_LETTERS)} ...`} </Typography>
          <Typography className={classes.mocionSeeMore} onClick={() => setShowFullMocion(!showFullMocion)}> {showAll ? '(Ver menos)' : '(Ver completa)'} </Typography>
        </>
      )
    }
  }

  const date = new Date(props.mocion.update_date)
  const dateParsed = props.mocion.update_date === '-' ? '-' :`${date.getUTCDate()} de ${months[date.getUTCMonth()]} de ${date.getUTCFullYear()}`
  return (
    <div className={classes.root}>
      <div className={classes.mocion}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            value={showOriginalMocion}
            exclusive
            onChange={( event: React.MouseEvent<HTMLElement>, value: boolean) => value !== null && setShowOriginalMocion(value)}
            style={{marginBottom: 8}}
            >
            <ToggleButton 
              value={false} 
              style={{
                textTransform: 'none', 
                borderRadius: props.mocion.original_mocion === props.mocion.mocion ? 8 : '8px 0px 0px 8px', 
                padding: '4px 16px',
                fontWeight: showOriginalMocion ? 500 : 600
              }}
            >
                Moción
            </ToggleButton>
            {
              props.mocion.original_mocion !== props.mocion.mocion
              &&
              <ToggleButton 
                value={true} 
                style={{
                  textTransform: 'none', 
                  borderRadius: '0px 8px 8px 0px', 
                  padding: '4px 16px',
                  fontWeight: showOriginalMocion ? 600 : 500
                }}
              >
                Moción original
              </ToggleButton>
            }
          </ToggleButtonGroup>
        </div>
        {renderMocion(showOriginalMocion ? props.mocion.original_mocion : props.mocion.mocion)}
      </div>
      <Divider style={{ margin: 8 }} />
      <div className={classes.other}>
        <div className={classes.otherRow}>
          <Typography className={classes.titleOther} style={{ marginRight: 8, fontWeight: '700' }}> Actualización:</Typography>
          <Typography style={{ display: 'inline', color: 'black' }}> {dateParsed} </Typography>
        </div>
        <div className={classes.otherRow}>
          <Typography className={classes.titleOther} style={{ marginRight: 8, fontWeight: '700' }}> Torneo / Ronda:</Typography>
          <Typography style={{ display: 'inline', color: 'black' }}> {props.mocion.tournament} / {props.mocion.round} </Typography>
        </div>
        <div className={classes.otherRow}>
          <Typography className={classes.titleOther} style={{ marginRight: 8, fontWeight: '700' }}> Tema:</Typography>
          <Typography style={{ display: 'inline', color: 'black' }}> {props.mocion.topic} </Typography>
        </div>
        <div className={classes.otherRow}>
          <Typography className={classes.titleOther} style={{ marginRight: 8, fontWeight: '700' }}> Aporte de:</Typography>
          {
            props.mocion.contributors.map((contributor, index) => (
              <Typography key={contributor} style={{ display: 'inline', color: 'black' }}> {contributor}{(index + 1) < props.mocion.contributors.length && ', '} </Typography>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default MocionRow;


