import { useState, useEffect, useRef, useCallback } from 'react';


interface UseFetchInterface<Type>{
    state: string,
    data: Type | null,
    error: any,
    refetch: Function,
    setData: Function
}

export default function useFetch<Type>(url: string) {

    const effectCalled = useRef(false);
    const [fetchState, setFetchState] = useState<UseFetchInterface<Type>>({
        state: 'idle',
        data: null,
        error: null,
        refetch: () => refetch(),
        setData: (data: Type) => setData(data)
    })

    const fetchData = useCallback(async (url: string) => {
        try {
            setFetchState((oldValue) => ({
                ...oldValue,
                data: null,
                error: null,
                state: "loading"
            }));
            const response = await fetch(url, {
                method: 'GET',
            })
            if (response.ok) {
                const json = await response.json();
                setFetchState(oldValue => ({
                    ...oldValue,
                    data: json,
                    state: "success",
                    error: null
                }));
            } else {
                setFetchState(oldValue => ({
                    ...oldValue,
                    data: null,
                    state: "error",
                    error: new Error(response.statusText)
                }));
            }
        } catch (error) {
            setFetchState(oldValue=> ({
                ...oldValue,
                data: null,
                state: "error",
                error: error
            }));
        }
    }, [])

    useEffect(() => {
        if (url && fetchData && !effectCalled.current) {
            effectCalled.current = true
            fetchData(url);
        }
    }, [url, fetchData])

    const refetch = () => {
        fetchData(url)
    }

    const setData = (data: Type) => {
        setFetchState(oldValue => ({
            ...oldValue,
            data: data,
        }));
    }


    return fetchState;
}