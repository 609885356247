import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme,  } from '@mui/styles';
import { analytics } from 'src/utils/analytics';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const topics = [
  'Otras políticas públicas',
  'Educación y cultura',
  'Salud y género',
  'Política internacional',
  'Ciudadanía y gobierno',
];


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '27%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}))

export interface IMocionesFilterTopicProps {
  topicFilters: string[]
  setTopicFilters: Function
}

const MocionesFilterTopic: React.FunctionComponent<IMocionesFilterTopicProps> = (props) => {

  const classes = useStyles()
  const theme = useTheme<Theme>()
  const downMd = useMediaQuery(theme.breakpoints.down('md'));


  const handleChange = (event: SelectChangeEvent<typeof props.topicFilters>) => {
    const { target: { value } } = event;
    analytics('Mociones - Filter Topic', {topic: value})
    props.setTopicFilters(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl className={classes.root} style={{marginTop: downMd ? 8 : 'auto'}}>
      <InputLabel id="demo-multiple-chip-label">Tema</InputLabel>
      <Select
        multiple
        value={props.topicFilters}
        onChange={handleChange}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {topics.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={props.topicFilters.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MocionesFilterTopic;
