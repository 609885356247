import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import MocionesTable from 'src/components/MocionesTable/MocionesTable';
import useMocioneroContext from 'src/context/useMocioneroContext';

export interface IMocionesViewProps {
}

const MocionesView: React.FunctionComponent<IMocionesViewProps> = (props) => {

  const { mociones } = useMocioneroContext()

  return (
    <>
      {
        mociones
          ? <>
            <MocionesTable mociones={mociones}/>
          </>
          : <div>
            <Typography style={{margin: '8px 0px'}}>
              Espera mientras se cargan las mociones :)
            </Typography>
            <LinearProgress />
            </div>
      }
    </>
  )
}

export default MocionesView;