
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';


const useStyles = makeStyles<Theme>((theme: Theme) => ({
    root: {
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        cursor: 'pointer',
        border: 0,
        fontSize: '1.1rem',
        lineHeight: '1.75',
        minWidth: '64px',
        padding: '6px 16px',
        background: 'white',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));

export interface INavbarButtonProps {
    onClick: Function
    children: React.ReactNode
    className?: string | undefined
    isSelected: boolean
}

const NavbarButton: React.FunctionComponent<INavbarButtonProps> = (props) => {

    const classes = useStyles(props)

    return (
        <button
            onClick={() => props.onClick()}
            className={`${classes.root} ${props.className}`}
            style={{
                fontWeight: props.isSelected ? '700' : '500',
            }}
        >
            {props.children}
        </button>
    )
}

export default NavbarButton;