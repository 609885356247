
import React, { useState } from 'react';
import { Mocion } from 'src/interfaces/Mocion'
import Button from 'src/components/MocioneroComponents/Button'
import MocionRow from 'src/components/Mocion/MocionRow';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { analytics } from 'src/utils/analytics';

const useStyles = makeStyles((theme: Theme) => ({
  buttonDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  mocionDiv: {
    boxShadow: "0px 8px 14px rgba(0, 0, 0, 0.25)",
    borderRadius: 8,
    marginTop: 32
  },
}))

export interface IRandomMocionViewProps {
  mociones: Mocion[]
}

const RandomMocionView: React.FunctionComponent<IRandomMocionViewProps> = (props) => {

  const classes = useStyles()
  const [tournament, setTournament] = useState<string>('-')
  const [round, setRound] = useState<string>('-')
  const [mocion, setMocion] = useState<string>('-')
  const [originalMocion, setOriginalMocion] = useState<string>('-')
  const [contributors, setContributors] = useState<string[]>(['-'])
  const [topic, setTopic] = useState<string>('-')
  const [updateDate, setUpdateDate] = useState<string>('-')

  const mocionToShow: Mocion = {
    mocion: mocion,
    tournament: tournament,
    round: round,
    contributors: contributors,
    id: 0,
    original_mocion: originalMocion,
    keywords: [],
    topic: topic,
    update_date: updateDate
  }

  function getRandomMocion() {
    analytics('Random Mocion - Get')
    // setDisableButton(true)
    setTournament('-')
    setRound('-')
    setMocion('-')
    setUpdateDate('-')
    setTopic('-')
    setContributors(['-'])
    setOriginalMocion('-')
    const randomMocion = props.mociones[Math.floor(Math.random() * props.mociones.length)]

    setMocion(randomMocion.mocion)
    setTournament(randomMocion.tournament)
    setRound(randomMocion.round)
    setOriginalMocion(randomMocion.original_mocion)
    setContributors(randomMocion.contributors)
    setTopic(randomMocion.topic)
    setUpdateDate(randomMocion.update_date)
    // getTournament(randomMocion)
  }

  // function getTournament(randomMocion: Mocion) {
  //   let tournamentInterval = setInterval(() => {
  //     setTournament(props.mociones[Math.floor(Math.random() * props.mociones.length)].tournament)
  //   }, 50)
  //   setTimeout(() => {
  //     clearInterval(tournamentInterval)
  //     setTournament(randomMocion.tournament)
  //     setRound(randomMocion.round)
  //     getMocion(randomMocion)
  //   }, 2000);
  // }

  // function getMocion(randomMocion: Mocion) {
  //   let mocionInterval = setInterval(() => {
  //     setMocion(props.mociones[Math.floor(Math.random() * props.mociones.length)].mocion)
  //   }, 50)
  //   setTimeout(() => {
  //     clearInterval(mocionInterval)
  //     setMocion(randomMocion.mocion)
  //     setOriginalMocion(randomMocion.original_mocion)
  //     setContributors(randomMocion.contributors)
  //     setTopic(randomMocion.topic)
  //     setUpdateDate(randomMocion.update_date)
  //     setDisableButton(false)
  //   }, 750);
  // }

  return (
    <div>
      <div className={classes.buttonDiv}>
        <Button
          onClick={getRandomMocion}
          style={{ padding: '4px 80px' }}
        >
          Obtener moción aleatoria
        </Button>
      </div>
      <div className={classes.mocionDiv}>
        <MocionRow mocion={mocionToShow} />
      </div>
    </div>
  )
}

export default RandomMocionView;